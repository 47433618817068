// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-services-custom-services-e-textbooks-js": () => import("./../../../src/pages/services/custom-services/e-textbooks.js" /* webpackChunkName: "component---src-pages-services-custom-services-e-textbooks-js" */),
  "component---src-pages-services-custom-services-index-js": () => import("./../../../src/pages/services/custom-services/index.js" /* webpackChunkName: "component---src-pages-services-custom-services-index-js" */),
  "component---src-pages-services-custom-services-print-on-demand-js": () => import("./../../../src/pages/services/custom-services/print-on-demand.js" /* webpackChunkName: "component---src-pages-services-custom-services-print-on-demand-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-retail-products-books-js": () => import("./../../../src/pages/services/retail-products/books.js" /* webpackChunkName: "component---src-pages-services-retail-products-books-js" */),
  "component---src-pages-services-retail-products-home-decor-js": () => import("./../../../src/pages/services/retail-products/home-decor.js" /* webpackChunkName: "component---src-pages-services-retail-products-home-decor-js" */),
  "component---src-pages-services-retail-products-index-js": () => import("./../../../src/pages/services/retail-products/index.js" /* webpackChunkName: "component---src-pages-services-retail-products-index-js" */),
  "component---src-pages-services-retail-products-office-products-js": () => import("./../../../src/pages/services/retail-products/office-products.js" /* webpackChunkName: "component---src-pages-services-retail-products-office-products-js" */),
  "component---src-pages-services-retail-products-pet-supplies-js": () => import("./../../../src/pages/services/retail-products/pet-supplies.js" /* webpackChunkName: "component---src-pages-services-retail-products-pet-supplies-js" */),
  "component---src-pages-services-retail-products-sport-and-outdoors-js": () => import("./../../../src/pages/services/retail-products/sport-and-outdoors.js" /* webpackChunkName: "component---src-pages-services-retail-products-sport-and-outdoors-js" */),
  "component---src-pages-services-retail-products-toys-and-games-js": () => import("./../../../src/pages/services/retail-products/toys-and-games.js" /* webpackChunkName: "component---src-pages-services-retail-products-toys-and-games-js" */),
  "component---src-pages-services-tailored-services-fulfillment-js": () => import("./../../../src/pages/services/tailored-services/fulfillment.js" /* webpackChunkName: "component---src-pages-services-tailored-services-fulfillment-js" */),
  "component---src-pages-services-tailored-services-index-js": () => import("./../../../src/pages/services/tailored-services/index.js" /* webpackChunkName: "component---src-pages-services-tailored-services-index-js" */),
  "component---src-pages-services-tailored-services-online-launchpad-js": () => import("./../../../src/pages/services/tailored-services/online-launchpad.js" /* webpackChunkName: "component---src-pages-services-tailored-services-online-launchpad-js" */),
  "component---src-pages-services-tailored-services-ordering-tools-js": () => import("./../../../src/pages/services/tailored-services/ordering-tools.js" /* webpackChunkName: "component---src-pages-services-tailored-services-ordering-tools-js" */),
  "component---src-pages-services-tailored-services-product-development-js": () => import("./../../../src/pages/services/tailored-services/product-development.js" /* webpackChunkName: "component---src-pages-services-tailored-services-product-development-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

